define("discourse/plugins/retort/discourse/templates/connectors/above-footer/emoji-picker-wrapper", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{
    emoji-picker
    retort=true
    isActive=this.isActive
    post=this.post
    emojiSelected=this.emojiSelected
    onEmojiPickerClose=this.onEmojiPickerClose
  }}
  */
  {
    "id": "FbeH9Rrn",
    "block": "[[[1,[28,[35,0],null,[[\"retort\",\"isActive\",\"post\",\"emojiSelected\",\"onEmojiPickerClose\"],[true,[30,0,[\"isActive\"]],[30,0,[\"post\"]],[30,0,[\"emojiSelected\"]],[30,0,[\"onEmojiPickerClose\"]]]]]]],[],false,[\"emoji-picker\"]]",
    "moduleName": "discourse/plugins/retort/discourse/templates/connectors/above-footer/emoji-picker-wrapper.hbs",
    "isStrictMode": false
  });
});