define("discourse/plugins/retort/discourse/connectors/above-footer/emoji-picker-wrapper", ["exports", "discourse/plugins/retort/discourse/lib/retort"], function (_exports, _retort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      _retort.default.setPicker(component);
    }
  };
});