define("discourse/plugins/retort/discourse/widgets/retort-remove-emoji", ["discourse/lib/ajax-error", "discourse/widgets/widget", "discourse-common/lib/get-owner", "discourse-i18n", "discourse/plugins/retort/discourse/lib/retort"], function (_ajaxError, _widget, _getOwner, _discourseI18n, _retort) {
  "use strict";

  (0, _widget.createWidget)("retort-remove-emoji", {
    tagName: "a.remove-retort",
    template: function (attrs, state) {
      var __h1 = __widget_helpers.iconNode;
      var _r = [];
      _r.push(__h1("times"));
      return _r;
    },
    buildKey: attrs => `retort-remove-${attrs.post.id}-${attrs.emoji}`,
    defaultState(_ref2) {
      let {
        emoji,
        post
      } = _ref2;
      return {
        emoji,
        post
      };
    },
    click() {
      const {
        post,
        emoji
      } = this.state;
      const dialog = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:dialog");
      dialog.confirm({
        title: _discourseI18n.default.t("retort.confirm_remove.title"),
        message: _discourseI18n.default.t("retort.confirm_remove.message", {
          emoji
        }),
        didConfirm: () => _retort.default.removeRetort(post, emoji).catch(_ajaxError.popupAjaxError)
      });
    }
  });
});