define("discourse/plugins/retort/discourse/initializers/retort-init", ["exports", "discourse/lib/plugin-api", "discourse-common/lib/get-owner", "discourse/plugins/retort/discourse/lib/retort"], function (_exports, _pluginApi, _getOwner, _retort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializePlugin(api) {
    const {
      retort_enabled
    } = api.container.lookup("site-settings:main");
    if (!retort_enabled) {
      return;
    }
    const currentUser = api.getCurrentUser();
    api.modifyClass("controller:preferences/notifications", {
      pluginId: "retort",
      actions: {
        save() {
          this.get('saveAttrNames').push('custom_fields');
          this._super();
        }
      }
    });
    if (currentUser?.custom_fields?.disable_retorts) {
      return;
    }
    api.decorateWidget("post-contents:after-cooked", helper => {
      let postId = helper.getModel().id;
      let post = _retort.default.postFor(postId);
      return helper.attach("post-retort-container", {
        post,
        currentUser
      });
    });
    api.addPostClassesCallback(attrs => {
      if (!_retort.default.disableShowForPost(attrs.id)) {
        return ["retort"];
      }
    });
    api.modifyClass("route:topic", {
      pluginId: "retort",
      setupController(controller, model) {
        const messageBus = (0, _getOwner.getOwnerWithFallback)(this).lookup("message-bus:main");
        _retort.default.initialize(messageBus, model);
        this._super(controller, model);
      }
    });
    api.addPostMenuButton("retort", attrs => {
      const post = _retort.default.postFor(attrs.id);
      if (!post.can_retort) {
        return;
      }
      return {
        action: "clickRetort",
        icon: "far-smile",
        title: "retort.title",
        position: "first",
        className: "retort"
      };
    });
    api.attachWidgetAction("post-menu", "clickRetort", function () {
      const post = this.findAncestorModel();
      _retort.default.openPicker(post);
    });
  }
  var _default = _exports.default = {
    name: "retort-button",
    initialize: function () {
      (0, _pluginApi.withPluginApi)("0.8.6", api => initializePlugin(api));
    }
  };
});